import React from "react";
import Layout from '../components/layout';
import { Container, Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Sample from '../assets/sample.pdf';
import { Helmet } from 'react-helmet';
import { useTheme } from '@mui/material/styles';
import SiteAlert from "../components/sitealert";

export default function Samplepage() {

    const theme = useTheme()

    return(
        <div>
            <Helmet>
                <title>Sample FARSIGHT study</title>
                <meta name="description" content={'free sample of farsight report'} />
                <meta name="keywords" content="free sector study sample, online purchase, real estate, healthcare, health care, finance, financial, energy, utilities, consumer discretionary, industrials, information technology, technology, Communication Services, Consumer Staples, materials, basic materials" />

                <meta property="og:title" content={'Sector studies online shop'} />
                <meta property="og:description" content={'free sample of farsight report'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={'https://farsight.report/sample'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'Sector studies online shop'} />
                <meta name="twitter:description" content={'free sample of farsight report'} />
            </Helmet>
            <Layout>
                <Container maxWidth='lg' sx={{
                    // background: theme.palette.background.default
                    }}>

                    {/* <SiteAlert /> */}

                    <Box sx={{
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center'}}>
                        <Typography 
                            textAlign={'center'} 
                            sx={{
                                fontSize: 48, 
                                fontWeight: 800, 
                                pt: '4%',
                                pb: '2%',
                                width: '100%', 
                                height: '100%'}}>
                                Sample study
                        </Typography>
                    </Box>

                    <Box sx={{display: "flex", width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>

                        <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                            <Typography textAlign={'justify'} sx={{
                                fontSize: 18, 
                                fontWeight: 300, 
                                mt: '2%',
                                // pt: '2%',
                                // pb: '2%',
                                width: '100%', 
                                height: '100%'}}>
                                Every sector study comes packed with information about different aspect of companies within the sector. As of now, each report 
                                comes in 6 chapters and more than 50 pages long. Interested to see what you will get? Here are first few pages of our sampled 
                                Energy sector study.
                            </Typography>
                        </Box>

                        <Box sx={{
                            display: "flex", 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            height: '65vh', 
                            width: '100%', 
                            mt: '4%',
                            mb: '5%',
                            border: 1,
                            borderColor: grey[700]}}>
                            <iframe src={`${Sample}#view=FitH`} loading="lazy" title="Test" width={'100%'} height={'100%'} style={{border: 2}}/>
                        </Box>
                       
                    </Box>
                    
                </Container>
            </Layout>
        </div>
    )
}