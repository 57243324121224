import React from "react";
import Layout from '../components/layout';
import { Container, Box, Typography, List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
// import Looks6Icon from '@mui/icons-material/Looks6';
import CircleIcon from '@mui/icons-material/Circle';
import { Helmet } from 'react-helmet';
import { useTheme } from '@mui/material/styles';
import SiteAlert from "../components/sitealert";

export default function Solutionpage() {

    const botColors = [
        '#00ffff',
        '#13ecff',
        '#27d8ff',
        '#3bc4ff',
        '#4eb1ff',
        '#629dff',
        '#7689ff',
        '#8976ff',
        '#9d62ff',
        '#b14eff',
        '#c43bff',
        '#d827ff',
        '#ec13ff']

    const details =[
        {
            primary: 'Sector breakdown', 
            secondary: 'General sector attributes and market cap evaluation and sector distributions', 
            icon: <LooksOneIcon />,
            color: botColors[1],
        },
        {
            primary: 'Sector return', 
            secondary: 'Holistic review of sector return over multiple horizons', 
            icon: <LooksTwoIcon />,
            color: botColors[3],
        },
        {
            primary: 'Ratio analysis', 
            secondary: 'Comprehensive ratio analysis across 6 pillars of financial statement analysis', 
            icon: <Looks3Icon />,
            color: botColors[5],
        },
        {
            primary: 'Conclusion', 
            secondary: 'Review of companies final score and its distribution in the sector', 
            icon: <Looks4Icon />,
            color: botColors[8],
        },
        {
            primary: 'Bonus chapter', 
            secondary: 'Evaluation of dividend payment across the sector', 
            icon: <Looks5Icon />,
            color: botColors[11],
        },
    ]

    const ratio_analysis = [
        {
            primary: 'Liquidity ratios', 
            secondary: 'Review of current, quick and cash ratios evaluation and sector distributions', 
            icon: <CircleIcon />, 
            color: botColors[0],
        },
        {
            primary: 'Solvency ratios', 
            secondary: 'Review of debt to asset, debt to equity, and debt to capitalization ratios evaluation and sector distributions',
            icon: <CircleIcon />,
            color: botColors[2],
        },
        {
            primary: 'Profitability margins', 
            secondary: 'Review of gross profit, operating profit, and netprofit margins evaluation and sector distributions',
            icon: <CircleIcon />,
            color: botColors[4],
        },
        {
            primary: 'Efficiency ratios', 
            secondary: 'Asset turnover, accounts payable turnover and accounts receivables turnover ratios evaluation and sector distributions',
            icon: <CircleIcon />,
            color: botColors[6],
        },
        {
            primary: 'Market value ratios', 
            secondary: 'Price to earning, price to book and price to sales ratios evaluation and sector distributions',
            icon: <CircleIcon />,
            color: botColors[8],
        },
        {
            primary: 'Cash flow ratios', 
            secondary: 'Operating cash flow, cash flow to debt and free cash flow to operating cash flow ratios evaluation and sector distributions',
            icon: <CircleIcon />,
            color: botColors[10],
        },
        {
            primary: 'Return margins', 
            secondary: 'Return on assets, return on equity and return on capital employed margins evaluation and sector distributions',
            icon: <CircleIcon />,
            color: botColors[12],
        },
    ]

    const theme = useTheme()

    return(
        <div >
            <Helmet>
                <title>FARSIGHT solutions</title>
                <meta name="description" content={'current farsight solutions'} />
                <meta name="keywords" content="weekly sector studies, sector breakdown, sector return, return margin, ratio analysis, tailored sector reports" />

                <meta property="og:title" content={'FARSIGHT solutions'} />
                <meta property="og:description" content={'current farsight solutions'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={'https://farsight.report/solutions'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'FARSIGHT solutions'} />
                <meta name="twitter:description" content={'current farsight solutions'} />
            </Helmet>
            <Layout>
                <>
                {/* <SiteAlert /> */}
                <Container maxWidth='lg' sx={{
                    // background: theme.palette.background.default
                    }}>

                    <Box sx={{
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center'}}>
                        <Typography 
                            textAlign={'center'} 
                            sx={{
                                fontSize: 48, 
                                fontWeight: 800, 
                                pt: '4%',
                                pb: '2%',
                                width: '100%', 
                                height: '100%'}}>
                                Solutions
                        </Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        width: '100%', 
                        height: '100%',}}>

                        <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Typography 
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md:18}, 
                                    fontWeight: 300, 
                                    pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                Currently, FARSIGHT offers two types of solutions:
                            </Typography>
                        </Box>

                        <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Typography textAlign={'justify'} sx={{
                                fontSize: 32, 
                                fontWeight: 400, 
                                lineHeight: 2,
                                pt: '2%',
                                pb: '2%',
                                width: '100%', 
                                height: '100%'}}>
                                Weekly sector studies
                            </Typography>
                        </Box>

                        <Box sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Typography 
                                // paragraph
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md:18}, 
                                    fontWeight: 300, 
                                    width: '100%', 
                                    height: '100%'}}>
                                Available for individual sector purchase or monthly subscription, these studies cater to both savvy investors and those with a keen interest in  
                                financial insights. Each study offers valuable, up-to-date financial studies and analyses, consisting of the following chapters:
                            </Typography>

                            <List sx={{width: '100%'}}>
                                {details.map((item) => (
                                    <ListItem key={item.primary}>

                                        <ListItemIcon sx={{color: item.color}}>
                                            {item.icon}
                                        </ListItemIcon>

                                        <ListItemText 
                                            primary={item.primary} 
                                            secondary={item.secondary} 
                                            primaryTypographyProps={{fontFamily: 'inherit', fontWeight: 400, fontSize: {xs: 16, md: 18}, textAlign: 'left'}}
                                            secondaryTypographyProps={{fontFamily: 'inherit', fontWeight: 300, fontSize: {xs: 14, md: 16}, textAlign: 'left'}}>
                                        </ListItemText>

                                    </ListItem>
                                ))}
                            </List>
                            
                            <Typography 
                                // paragraph
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md:18}, 
                                    fontWeight: 300, 
                                    width: '100%', 
                                    height: '100%'}}>
                                The central focus of each study is dedicated to ratio analysis. In this chapter, we delve into six aspects of the financial health of all 
                                companies within the sector. These categories, when combined, paint a thorough picture of each company's business operations for our readers. 
                                What sets FARSIGHT reports apart from other products on the market is our comprehensive evaluation of the entire sector. Each chapter analyzes 
                                the following category and its associated distribution and given score:
                            </Typography>

                            <List sx={{width: '100%'}}>
                                {ratio_analysis.map((item) => (
                                    <ListItem key={item.primary}>
                                        <ListItemIcon sx={{color: item.color}}>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={item.primary} 
                                            secondary={item.secondary} 
                                            primaryTypographyProps={{fontFamily: 'inherit', fontWeight: 400, fontSize: {xs: 16, md: 18}, textAlign: 'left'}}
                                            secondaryTypographyProps={{fontFamily: 'inherit', fontWeight: 300, fontSize: {xs: 14, md: 16}, textAlign: 'left'}}></ListItemText>
                                    </ListItem>
                                ))}
                            </List>

                        </Box>

                        <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Typography textAlign={'justify'} sx={{
                                fontSize: 32, 
                                fontWeight: 400, 
                                lineHeight: 2,
                                pt: '2%',
                                // pb: '2%',
                                width: '100%', 
                                height: '100%'}}>
                                Tailored sector reports
                            </Typography>
                        </Box>

                        <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Typography 
                                paragraph
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md:18}, 
                                    fontWeight: 300, 
                                    pt: '2%',
                                    pb: '5%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    We are thrilled to extend our services, offering tailored studies and consultancy designed to meet the unique requirements of family offices, 
                                    hedge funds, and other institutional investors. Our team of experts is fully committed to providing comprehensive insights and guidance to support 
                                    your investment strategies. To initiate a consultation or to inquire further about our offerings, please contact us via the formal 
                                    channels provided on our website. We look forward to the opportunity to assist you in your data processing decisions.
                            </Typography>
                        </Box>

                        <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'center', height: '25vh%', width: '100%'}}></Box>
                       
                    </Box>
                    
                </Container>
                </>
            </Layout>
        </div>
    )
}