import React, { useEffect, useState } from "react";
import { Typography, Box, CardMedia, Button, Grid, Card, CardContent, Divider, CardActionArea, Icon, Avatar } from "@mui/material";
import { buttonColors, contrastTextColors } from '../theme/colors';
import { UserAuth } from "../context/userContext";
import { useNavigate } from "react-router-dom";
import { functions } from '../firebase/index';
import { useStripe, } from '@stripe/react-stripe-js';
import { httpsCallable } from "firebase/functions";
import { grey } from "@mui/material/colors";
import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import {styled} from "@mui/material/styles";
import { Link } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';

const StyledCard = styled(Card)({
    borderRadius: "1rem",
    boxShadow: "none",
    position: "relative",
    maxWidth: {
        xs: '550px', sm: '650px', md: '600px'
    },
    // minWidth: 200,
    minHeight: 300,
    transition: "0.2s",
    "&:hover": {transform: "scale(1.1)",},
    "&:after": {
        transform: "scale(1.1)",
        content: '""',
        display: "block",
        position: "absolute",
        width: "100%",
        height: "64%",
        bottom: 0,
        zIndex: 1,
        // background: "linear-gradient(to top, #000, rgba(0,0,0,0))",
        background: "linear-gradient(to bottom, rgb(20, 20, 20), rgb(20, 20, 20) 40%, rgba(200, 200, 200, 0.2))"
    },
  });
  
  const StyledCardMedia = styled(CardMedia)({
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 0,
    backgroundPosition: "top",
  });
  
  const Content = styled("div")(({ theme }) => ({
    padding: theme.spacing(3, 2),
    position: "absolute",
    zIndex: 2,
    bottom: 0,
    width: "100%",
  }));

function FreeProductCard(props) {

    // const priceArray = props.product.prices

    // const monthlyPrice = priceArray.filter(
    //     (pr) => pr.active === true && pr.type === "recurring" && pr.interval === "month"
    //     )

    // const yearlyPrice = priceArray.filter(
    //     (pr) => pr.active === true && pr.type === "recurring" && pr.interval === "year"
    //     )

    // const purchasePrice = priceArray.filter(
    //     (pr) => pr.active === true && pr.type === "one_time"
    //     )

    // const [per, setPer] = useState('Month')
    // const [price, setPrice] = useState(monthlyPrice[0])
    // const [stripeMod, setStripeMod] = useState('subscription')
    // const [buttonText, setButtonText] = useState('Subscribe to sector review')

    // const { isUserSignedIn } = UserAuth()
    // const stripe = useStripe();
    // const CreateStripeCheckoutA = httpsCallable(functions, 'CreateStripeCheckoutA')
    // const navigate = useNavigate()
    const theme = useTheme()



    useEffect(() => {

    //     if(props.plan.type === "recurring") {

    //         setButtonText('Subscribe to sector review')
    //         setStripeMod('subscription')

    //         if (props.plan.interval === 'month') {
    //             setPer('Month')
    //             setPrice(monthlyPrice[0])
    //             // console.log(monthlyPrice[0])
    //         } else {
    //             setPer('Year')
    //             setPrice(yearlyPrice[0])
    //         }

    //     } else {
    //         setPer('Study')
    //         setPrice(purchasePrice[0])
    //         setButtonText('Buy sector review')
    //         setStripeMod('payment')
            
    //     }

    //     // console.log(props.plan)

    }, [props.plan])

    return(
        <Grid 
            item 
            spacing={5}
            key={props.product.id} 
            xs={12} sm={9} md={6} lg={4} 
            sx={{ 
                height: '100%',
                ml: {xs: 0, sm: '12.5%', md: 0},
                }}>

            {/* <Button 
                onClick={(e) => SideOpen(sector_subscribed)}
                onClick={(e) => SideOpen(sector_subscribed)}
                variant='outlined'
                component={Card}
                to={'/energy'}> */}

            <Card 
                variant="outlined" 
                sx={{
                    maxWidth: {xs: '550px', sm: '650px', md: '600px'},
                    margin: "auto",
                    borderRadius: "0.8rem",
                    transition: "0.2s",
                    // boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
                    "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
                        borderRadius: "1.5rem",
                    },
                    // "&:hover": {
                    //   boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
                    // }
                    // "&:after": {
                    //     background: "linear-gradient(to top, #000, rgba(0,0,0,0))",
                    // }
                }}>
                <CardActionArea 
                component={Link} 
                // to="/energy"
                to={'/'+props.product.name.split("Sector Study")[0].toLowerCase().replace(/\s+/g, '')}
                // props.product.name.split("Study")
                >
                <CardMedia image={props.product.images[0]} sx={{paddingTop: "56.25%"}} />
                <Avatar 
                    // src={"https://images.unsplash.com/photo-1517147177326-b37599372b73?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2229&q=80"} 
                    sx={{
                        position: "absolute", 
                        width: 50,
                        height: 50, zIndex: 1,
                        margin: "-25px 0 0 80%",
                        border: "2px solid #fff",
                        bgcolor: grey[50]
                    }}>
                    <SettingsIcon sx={{color: grey[900], fontSize: 36}}/>
                </Avatar>
                {/* <CardMedia 
                image={"https://images.unsplash.com/photo-1517147177326-b37599372b73?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2229&q=80"} 
                sx={{ position: "absolute", 
                    width: 50,
                    height: 50, zIndex: 1,
                    margin: "-25px 0 0 80%",
                    border: "2px solid #fff",
                    }} /> */}

                {/* </Avatar> */}
                <CardContent sx={{
                        textAlign: "left",
                        padding: theme.spacing * 3,
                        background: "linear-gradient(to top, rgb(60, 60, 60), rgba(145, 180, 215, 1))"
                    }}>
                    <Typography 
                        textAlign={'left'} 
                        sx={{
                            // padding: theme.spacing(-2, 2),
                            // position: "absolute",
                            color: "#fff",
                            fontSize: "1.5rem",
                            fontWeight: "500",
                            lineHeight: 1.5}}>
                        {props.product.name.split("Study")}
                    </Typography>
                    <Divider sx={{
                        // margin: `${theme.spacing.unit * 3}px 0`,
                        marginTop: '1rem',
                        bgcolor: "#fff"}}/>
                    <Typography sx={{
                        color: "rgba(255, 255, 255, 0.72)",
                        lineHeight: 1.5,
                        "&:last-child": {
                            marginTop: "1rem",
                        },
                    }}>{props.product.description}</Typography>    
                </CardContent>
                </CardActionArea>
            </Card>

            {/* <StyledCard>
                <StyledCardMedia image={props.product.images[0]} />
                <Content>
                    <Typography sx={{
                        color: "#fff",
                        fontSize: "1.4rem",
                        fontWeight: "500",
                        lineHeight: 1.2,
                    }}>{props.product.name.split("Study")}</Typography>
                    <Typography sx={{
                        color: "rgba(255, 255, 255, 0.72)",
                        lineHeight: 1.5,
                        "&:last-child": {
                            marginTop: "1rem",
                        },
                    }}>{props.product.description}</Typography>
                </Content>
            </StyledCard> */}

            {/* </Button> */}

            {/* <Box sx={{
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: {xs: '550px', sm: '650px', md: '600px'},
                // width: {xs: '100%', sm: '66%'},
                minWidth: '350px',
                border: 0.5,
                borderColor: grey[400],
                background: 'white',
                borderRadius: '0.4em',
                }}> */}

                {/* <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    width: '100%',
                    height: '100%',
                    mt: '5%',
                    }}>
                    <CardMedia 
                        component={'img'}
                        image={props.product.images[0]} 
                        sx={{ 
                            maxHeight: 270,
                            maxWidth: {xs: '480px', sm: '540px', md: '500px'},
                            // height: '100%', 
                            width: '80%', 
                            alignSelf: 'center', 
                            borderRadius: '6px'}}/>
                </Box> */}

                {/* <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    width: '100%',
                    mt: '5%', 
                    }}>
                    <Typography 
                        textAlign={'center'} 
                        sx={{fontSize: 20, fontWeight: '500', lineHeight: 2}}>
                        {props.product.name}
                    </Typography>
                </Box> */}

                {/* <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    width: '100%', 
                    mt: '5%',
                    }}>
                    <Typography 
                        paragraph 
                        textAlign={'justify'} 
                        sx={{
                            width: '80%',
                            fontSize: 16, 
                            fontWeight: 300, 
                            }}>
                        {props.product.description}
                    </Typography>
                </Box> */}
                
                {/* <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    width: '100%',
                    mt: '2%',
                    mb: '2%',
                    height: {xs: '50px', sm: '60px'},
                    }}>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center', 
                        justifyContent: 'right', 
                        // background: 'yellow',
                        width: '100%',
                        height: '100%',
                        }}>
                        <Typography 
                            sx={{
                                fontSize: {xs: 30, md: 34, lg: 32}, 
                                fontWeight: 700, 
                                mr: '5%'
                                }}>
                                ${price.unit_amount/100}
                        </Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        }}>

                        <Typography sx={{fontSize: {xs: 16, md:18}, ml: '5%'}}>
                            per
                        </Typography>

                        <Typography sx={{fontSize: {xs: 16, md:18}, ml: '5%'}}>
                            {per}
                        </Typography>

                    </Box>
                </Box> */}

                {/* <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    width: '100%', 
                    mt: '5%',
                    mb: '5%'
                    }}>

                    <Button                                 
                        disableElevation
                        variant='outlined'  
                        value={price.id}
                        onClick={(e) => {
                            if (!isUserSignedIn()) {
                                navigate('/signin')
                                console.log('user is not signed in.')
                            } else {
                                const paydata = e.currentTarget.value
                                const data = {
                                    mode: stripeMod, 
                                    price: paydata, 
                                    sector: props.product.metadata.sector, 
                                    name: props.product.name
                                }
                                console.log(data)
                                CreateStripeCheckoutA(data)
                                .then(response => {
                                    console.log(response)
                                    const session_Id = response.data.id
                                    stripe.redirectToCheckout({sessionId: session_Id})
                                })
                                .catch((error) => {
                                    const code = error.code;
                                    const message = error.message;
                                    const details = error.details;
                                    console.log('Error code: ', code)
                                    console.log('Msg: ', message)
                                    console.log('Details: ', details)
                                })
                                    }
                                }}
                        sx={{
                            border: 1.6,
                            borderColor: buttonColors[props.index],
                            // backgroundColor: grey[100],
                            // borderColor: buttonColors[props.index],
                            // backgroundColor: alpha(buttonColors[props.index], 0.75),
                            // color: contrastTextColors[props.index],
                            backgroundColor: alpha(buttonColors[props.index + 1], 0.2),
                            color: grey[700],
                            '&:hover': {
                                border: 2,
                                borderColor: alpha(buttonColors[props.index], 0.75),
                                // backgroundColor: alpha(buttonColors[props.index + 1], 0.2),
                                // color: grey[700],
                                backgroundColor: alpha(buttonColors[props.index], 0.75),
                                color: contrastTextColors[props.index],
                            },
                            width: '80%', 
                            height: 40, 
                            fontFamily: 'inherit',
                            borderRadius: 20}}
                            >

                            <Typography 
                                textAlign={'center'} 
                                sx={{
                                    fontSize: 16, 
                                    fontWeight: 400, 
                                    // color: grey[700]
                                    // color: contrastTextColors[props.index]
                                    }}>
                                {buttonText}
                            </Typography>


                    </Button>

                </Box> */}

            {/* </Box> */}

        </Grid>
    )
}

export default FreeProductCard

