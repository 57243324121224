import React , { useEffect, useState } from "react";
import { Box, Divider, Typography} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { grey } from "@mui/material/colors";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
    //   backgroundColor: theme.palette.common.black,
      backgroundColor: grey[800],
      color: theme.palette.common.white,
    },
    // [`&.${tableCellClasses.body}`]: {
    //   fontSize: 14,
    // },
  }));

export default function SearchCard({input, ticker, size}) {

    console.log(size)

    const data = []
    const metrics = ['Liquidity', 'Solvency', 'Profitability', 'Efficiency', 'Mkt. Value', 'CashFlow', 'Management']
    metrics.forEach( (m) => {
        const name = m.charAt(0) + m.charAt(m.length-1)
        data.push({mt: m, sc: input[m], rk: input[name]})
    })

    return(
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', 
                alignContent: 'center',
                width: {xs: '100%', md: '60%'}, 
                }}>

                <Box sx={{width: '95%'}}>
                    <Typography
                        textAlign={'center'} 
                        sx={{
                            fontSize: 48, 
                            fontWeight: '500',
                            color: grey[800], 
                            lineHeight: 2}}>
                            {ticker}
                    </Typography>
                </Box>

                <Box sx={{width: '95%', mt: '1rem'}}>
                    <Divider variant='middle' flexItem />
                </Box>
                
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '95%',
                    mt: '1rem',
                    }}>
                    <Box sx={{
                        display: {xs: 'none', md: 'flex'},
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: {xs: '0%', md: '50%'}
                        }}>
                        <Typography
                            // textAlign={'right'} 
                            sx={{
                                fontSize: 24, 
                                fontWeight: 500,
                                color: grey[800], 
                                lineHeight: 2}}>
                                Sector:
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: {xs: 'center', md: 'left'},
                        width: {xs: '100%', md: '50%'}
                        }}>
                    <Typography
                        // textAlign={'left'} 
                        sx={{
                            fontSize: 24, 
                            fontWeight: 400,
                            color: grey[700], 
                            lineHeight: 2}}>
                            {input.industry}
                    </Typography>
                    </Box>
                    {/* <Typography
                        textAlign={'center'} 
                        sx={{
                            fontSize: 24, 
                            fontWeight: 400,
                            color: grey[700], 
                            lineHeight: 2}}>
                            {input.industry}
                    </Typography> */}
                </Box>

                <Box 
                    sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '95%', 
                    }}>

                    <Box sx={{width: '50%'}}>
                        <Typography
                            textAlign={'center'} 
                            sx={{
                                fontSize: 24, 
                                fontWeight: 500,
                                color: grey[800], 
                                lineHeight: 2}}>
                                Rank:
                        </Typography>
                    </Box>

                    <Box sx={{width: '50%'}}>
                        <Typography
                            textAlign={'left'} 
                            sx={{
                                fontSize: 24, 
                                fontWeight: 400,
                                color: grey[700], 
                                lineHeight: 2}}>
                                {input.Te} (Out of {size})
                        </Typography>
                    </Box>

                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '95%', 
                    }}>
                    <Box sx={{width: '50%'}}>
                        <Typography
                            textAlign={'center'} 
                            sx={{
                                fontSize: 24, 
                                fontWeight: 500,
                                color: grey[800], 
                                lineHeight: 2}}>
                                Score:
                        </Typography>
                    </Box>
                    <Box sx={{width: '50%'}}>
                        <Typography
                            textAlign={'left'} 
                            sx={{
                                fontSize: 24, 
                                fontWeight: 400,
                                color: grey[700], 
                                lineHeight: 2}}>
                                {Math.round(100 * input['Total Score']) / 100}
                        </Typography>
                    </Box>

                </Box>

                <Box sx={{width: '95%', mt: '1rem'}}>
                    <Divider variant='fullWidth' flexItem />
                </Box>

                <Box border={1} sx={{width: '95%', mt: '2rem'}}>
                    <TableContainer component={'div'}>
                        <Table 
                            sx={{ minWidth: 280 }} 
                            aria-label="Ticker individual table">

                            <TableHead>
                                <TableRow>
                                    <StyledTableCell key={1} align='center'>Metric</StyledTableCell>
                                    <StyledTableCell key={2} align='center'>Score</StyledTableCell>
                                    <StyledTableCell key={3} align='center'>Rank</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {data.map((row) => (
                                <TableRow
                                    key={row.mt}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        '&:nth-of-type(even)': {
                                            backgroundColor: grey[100],
                                            }, 
                                        '&:nth-of-type(odd)': {
                                            backgroundColor: 'white',
                                            }, 
                                    }}
                                    >
                                    <TableCell align='center' component="th" scope="row">
                                        {row.mt}
                                    </TableCell>
                                    <TableCell align='center'>{Math.round(100 * row.sc) / 100}</TableCell>
                                    <TableCell align='center'>{row.rk}</TableCell>

                                </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Box>

            </Box>
        </>
    )
}