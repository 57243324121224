import React from "react";
import { Box, Typography } from "@mui/material";

export function Chapterheader(props) {

    return(
        <Box sx={{width: '100%', mt: '4%', mb: '2%'}}>
            <Typography
                textAlign={'left'} 
                sx={{fontSize: {xs: 24, md:28}, fontWeight: '500', lineHeight: 2}}>
                {props.header}
            </Typography>
        </Box>
    )
}

export function Sectionheader(props) {

    return(
        <Box sx={{
            width: '100%', 
            mb: '1%'}}>
            <Typography
                textAlign={'left'} 
                sx={{fontSize: {xs: 20, md:24}, fontWeight: '400', lineHeight: 2}}>
                {props.head}
            </Typography>
        </Box>

    ) 
}

export function Figurecaption(props) {

    return(
        <Typography
            component='div'
            paragraph
            textAlign={'center'}
            sx={{
                mt: '1%',
                fontSize: {xs: 12, md: 14}, fontWeight: '600', lineHeight: 1.5}}>
            {props.caption}
    </Typography>
    )
}