import React from "react";
import { Typography, Box, Button, CircularProgress, Card, CardActionArea, CardMedia, CardContent, Divider, Grid, Stack } from "@mui/material";
import { buttonColors, contrastTextColors } from '../theme/colors';
import { grey } from "@mui/material/colors";
import { alpha } from "@mui/material";
import { useTheme, styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import Paper from '@mui/material/Paper';

const StyledButton = styled(Button)(({theme}) => ({
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.5,
    textTransform: 'None',
}))

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    // ...theme.applyStyles('dark', {
    //   backgroundColor: '#1A2027',
    // }),
  }));

const StyledCard = styled(Card)({
    borderRadius: "0.75rem",
    // boxShadow: "none",
    position: "relative",
    // width: {xs: '90%', md: '75%'},
    height: 175,
    boxShadow: "5px 5px 10px rgba(34, 35, 58, 0.15)",
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      width: "100%",
      height: "90%",
      bottom: 0,
      zIndex: 1,
      background: "linear-gradient(to top, #001 20%, rgba(1,0.1,0.1,0))",
    },
  });

const StyledCardMedia = styled(CardMedia)({
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 0,
    backgroundPosition: "top",
  });
  
const Content = styled("div")(({ theme }) => ({
    padding: theme.spacing(1.5, 3),
    position: "absolute",
    zIndex: 2,
    bottom: 0,
    width: "100%",
  }));

function SubscriptionBlocks(sub, index, buttonColors) {

    // let mtop = 0
    const sector_subscribed = sub.items[0].price.product.metadata.sector 
    const Cap_sector = sector_subscribed.charAt(0).toUpperCase() + sector_subscribed.slice(1)
    // console.log(sector_subscribed.replace(/\s+/g, ''))
    // console.log(buttonColors[index])
    const cardImage = sub.items[0].price.product.images[0]
    // const cardText = sub.items[0].price.product.description.split(".")[0]
    // const theme = useTheme()

    // if (index >= 1) {
    //     mtop = 0
    // } else {
    //     mtop = 0
    // }

    // console.log('Subscription is:', sub)
    // console.log('Index is ', index, mtop)

    // async function SideOpen(sector) {

    //     const SignedURL = httpsCallable(functions, 'SignedURL')
    
    //     const fullName = sector.replace(' ', '')
    //     const docRef = doc(db, "updates", fullName)
    //     const docSnap = await getDoc(docRef)
    
    //     if (docSnap.exists()) {
    //         const report = docSnap.data().latestReport
    //         const storagePath = `${fullName}/${report}`
    
    //         SignedURL({path: storagePath})
    //         .then( result => {
    //             const url = result.data.url[0]
    //             window.open(url, "_blank")
    //         })
    //         .catch( (error) => {
    //             const errorCode = error.code;
    //             const errorMessage = error.message;
    //             console.log('Error code: ', errorCode)
    //             console.log('Error Msg.: ', errorMessage)
    //         })
    
    //     } else {
    
    //     }
    
    // }

    return(

        // <Box key={index} sx={{
        //     width: {xs: '80%', sm: '66%'},
        //     maxWidth: '400px',
        //     display: 'flex',
        //     alignItems: 'center',
        //     justifyItems: 'center', 
        //     mt: mtop,  
        //     border: 1, 
        //     }}>

        //     {Cap_sector}

        // {/* //         <Button 
        // //             // onClick={(e) => SideOpen(sector_subscribed)}
        // //             // onClick={(e) => SideOpen(sector_subscribed)}
        // //             variant='outlined'
        // //             component={Link}
        // //             to={'/'+sector_subscribed.replace(/\s+/g, '')}
        // //             fullWidth
        // //             sx={{
        // //                 // mb: '5%',
        // //                 pt: '2%',
        // //                 pb: '2%',
        // //                 border: 0.5,
        // //                 fontFamily: 'inherit',
        // //                 color: contrastTextColors[index+1],
        // //                 borderColor: alpha(buttonColors[index], 0.6),
        // //                 background: alpha(buttonColors[index], 0.6),
        // //                 '&:hover': {
        // //                     border: 0.5,
        // //                     color: contrastTextColors[index],
        // //                     background: alpha(buttonColors[index], 0.9),
        // //                     borderColor: alpha(buttonColors[index], 0.9),
        // //                     // fontWeight: 500,
        // //                     },
        // //                 }}>
        // //             <Typography 
        // //                 textAlign={'center'} 
        // //                 sx={{
        // //                     fontSize: {xs: 14, sm: 16},
        // //                     fontWeight: 400,
        // //                     }}>
        // //                 {Cap_sector} Sector
        // //             </Typography>
        // //         </Button> */}
            
        // </Box>
        
    <Grid key={index} item xs={12} md={6} lg={4}>
        <StyledButton  
            component={Link} 
            to={'/'+sector_subscribed.replace(/\s+/g, '')} 
            fullWidth 
            sx={{fontFamily: 'inherit', fontWeight: 500,}}>
            <StyledCard key={index} variant='elevation' sx={{
                width: '100%',
                borderColor: grey[500],
                // boxShadow: `0px 0px 10px ${buttonColors[index]}`,
                }}>
                <StyledCardMedia image={cardImage} /> 
                <Content>
                    <Typography 
                        textAlign={'left'} 
                        component="span"
                        noWrap={true}
                        sx={{
                            // padding: theme.spacing(-3, 3),
                            // position: "absolute",
                            color: "#fff",
                            // color: buttonColors[index],
                            fontSize: 24,
                            fontWeight: 600,
                            lineHeight: 1.5}}>
                        {Cap_sector}
                    </Typography>
                </Content>  
                {/* </CardActionArea> */}
            </StyledCard>
        </StyledButton>
        </Grid>

    )
}

export default function ActiveSubBlock(props) {

    const subNo = props.activeSubs.length

    return(
        <Box sx={{
            width: '100%',
            display: 'flex',
            // flexDirection: 'column', 
            alignItems: 'center',
            justifyItems: 'center',

            }}>
            { 
                props.subLoading  
                ? 
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    mt: {xs: '8%', sm: '2%'},
                    mb: {xs: '4%', sm: '4%'},
                    width: '100%'}}>
                    <CircularProgress size={'3rem'}/>
                </Box>
                : 
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    mt: '4%',
                    mb: '4%',
                    // flexGrow: 1,
                    width: '100%'}}>
                    {
                        subNo > 0 
                        ?
                        <Grid container spacing={2} sx={{width: '90%'}}>
                            {/* <Grid item xs={12} md={6}> */}
                                { 
                                props.activeSubs.map(
                                    (sub, index) => 
                                        SubscriptionBlocks(sub, index, buttonColors)
                                    )
                                }
                            {/* </Grid>
                            <Grid item xs={12} md={6}>
                                <Item>xs=4</Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item>xs=4</Item>
                            </Grid>
                            <Grid item xs={8}>
                                <Item>xs=8</Item>
                            </Grid> */}
                            {/* </Grid> */}
                        </Grid>
                        :
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            width: '100%'}}>
                            <Typography 
                                sx={{
                                    fontSize: {xs: 16, sm: 24},
                                    fontWeight: 400, 
                                    color: grey[800]
                                    }}>
                                You have no active subscriptions.
                            </Typography>
                        </Box>
                    }
                </Box>
            }
        </Box>
    )
}