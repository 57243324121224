import React , { useEffect, useState } from "react";
import { Box, Typography, CircularProgress} from "@mui/material";
import { Figurecaption } from './reportBlocks';
import { RatioPie } from "./figureBlocks";
import { Sectionheader } from "./reportBlocks";

export default function Ratios({sector}) {

    const [loading, setLoading] = useState(true)

    useEffect( () => {

        setLoading(false)
        return( () => {})

    }, [])

    return(
       <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
                : 
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    width: '100%',}}>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'}
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Ratio analysis is a fundamental tool for analyzing a company's financial health and comparing its performance
                        to that of its competitors. By examining the relationships between various elements of the financial statements, 
                        such as assets, liabilities, equity, revenue, and expenses, ratio analysis provides critical takeaways into a 
                        company's operational effectiveness and financial health. This method allows investors, creditors, and management 
                        to make informed decisions by identifying sector-wide trends, comparing performance with industry benchmarks, and 
                        evaluating the company's ability to meet its short- and long-term goals. This study conducts and documents ratio 
                        analysis for seven (7) categories, as
                        illustrated in <Box fontWeight='fontWeightMedium' display='inline'>Figure 9</Box>. Each ratio category assesses 
                        a distinct aspect of the financial health and performance of the companies in the sector, such as their ability 
                        to generate sales, earn profits, or meet debt obligations.
                    </Typography>

                    <Box sx={{
                        border:1,
                        borderRadius: 2,
                        background: 'white',
                        width: {xs: '95%', sm: '85%', md: '65%', lg: '50%'}, 
                        height: {xs: '400px', sm: '440px'},
                        minWidth: '375px'
                        }}>
                        <Box sx={{m: 1, height: '100%'}}>
                            {RatioPie()}
                        </Box>
                    </Box>

                    <Figurecaption caption={'Figure 9: Breakdown of ratio analysis'} />

                    <Sectionheader head={'3.0.1 Study Layout'} />

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'}
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Analysis sections of this chapter have consistent layout. Each section begins by introducing financial 
                        metrics, detailing their definitions, and outlining their applications. Subsequently, these metrics are computed and 
                        presented for all companies within the {sector} sector, facilitating a comprehensive comparison and evaluation 
                        of their financial performance. To mitigate the impact of outliers, winsorization techniques are employed, ensuring 
                        the robustness of the analyses. Various statistical parameters for each metric are calculated and presented, 
                        providing readers with a practical benchmark for comparing the performance of any company in the sector.
                    </Typography>

                    <Sectionheader head={'3.0.2 Scoring System'} />

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'}
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        To enable sector-wide comparisons, we've developed a proprietary scoring system. This system assigns scores ranging 
                        from <Box fontWeight='fontWeightMedium' display='inline'>0.5</Box> to <Box fontWeight='fontWeightMedium' 
                        display='inline'>10</Box> to each metric, using a proprietary algorithm that establishes the joint distribution of 
                        the metric and annual returns across the sector, while also considering accepted practical values. This process is 
                        repeated for three (3) metrics in each category. Subsequently, a composite score is calculated for each company, 
                        representing the weighted average of individual scores within its ratio category. This composite score serves as a 
                        key indicator of the company's overall financial health in the given area and its performance relative to its peers. 
                        The distribution of composite scores versus 1-year returns across the sector is presented at the end of each section. 
                        Current study uses <Box fontWeight='fontWeightMedium' display='inline'>FARSIGHT2.2 {'\u00AE'}</Box> scoring methodology.
                    </Typography>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'}
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        After evaluating the performance of each company across the sector in seven ratio categories, a final score is assigned 
                        to each company. This score is calculated as the weighted average of the scores in each financial category. The weights 
                        for each component are determined through a machine learning algorithm, which assesses the significance of each metric 
                        in relation to the sector-wide performance of each company. Companies are then ranked based on their final scores, with 
                        the top performers identified and listed on a weekly basis. These comprehensive analyses not only help to understand each 
                        company's financial standing but also identify broader sector trends. By analyzing how companies in the {sector} sector 
                        perform across these ratio categories, readers can gain useful knowledge into industry dynamics and pinpoint areas for 
                        improvement. This study is committed to delivering clear and insightful analyses that contribute to a better understanding 
                        of the sector.
                    </Typography>

                </Box>
            }
        </div>
    )

}