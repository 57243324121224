import React , { useEffect, useState } from "react";
import { Box, Typography, CircularProgress, InputBase, Paper, Card, CardHeader, Grid, Stack} from "@mui/material";
import { Sectionheader, Figurecaption } from './reportBlocks';
import { MetricsStats, ScoreTable } from './tableblocks';
import { MetricSector, DivTop10, MetricSector2 } from './figureBlocks';
import { getDocs, collection, query, orderBy, limit } from "firebase/firestore";
import { db } from '../firebase/index';
import { styled, alpha } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { TypeSpecimenOutlined } from "@mui/icons-material";
import SearchCard from "./searchCard";
import { RadarScore } from './figureBlocks';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '15ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
  }));

async function fetchScores(sector) {

    const tickList = {}

    const weeklyRef = collection(db, 'testScores')
    const q = query(weeklyRef, orderBy("at", "desc"), limit(1))
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach((doc) => {
        tickList['tickers'] = doc.data()['tickers']
        tickList['scores'] = doc.data()['scores']
        tickList['industries'] = doc.data()['industries']
      })

    return tickList
}

export default function Search({sector, input, size}) {

    const [loading, setLoading] = useState(true)
    const [source, setSource] = useState([])
    const [query, setQuery] = useState([])
    const [results, setResults] = useState([]);
    const [compNo, setCompNo] = useState(0)
    const[radar, setRadar] = useState([])


    const handleChange = (event) => {
        const src = event.target.value.toUpperCase()
        // console.log(src)
        setQuery(src)
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        if (query && query.length < 2) {

        } else {
            function myTest(t) {return t === query}
            const found = source.tickers.find(myTest)
            const tickScore = source.scores[String(found).toUpperCase()]

            // console.log(source)
            const industry = source.industries[String(tickScore.industry)]

            const radarData = [
                tickScore.Liquidity, tickScore.Solvency,
                tickScore.Profitability, tickScore.Efficiency,
                tickScore['Mkt. Value'], tickScore.CashFlow,
                tickScore.Management
            ]

            const radarInd = [
                industry.Liquidity, industry.Solvency,
                industry.Profitability, industry.Efficiency,
                industry['Mkt. Value'], industry.CashFlow,
                industry.Management
            ]

            // console.log(radarData)
            // console.log(radarInd)
            setResults([tickScore])
            setRadar([
                {'data': radarData, 'name': query}, 
                {'data': radarInd, 'name': 'Industry Average'}
            ])
        }
    }

    useEffect( () => {

        // fetchScores(sector)
        // .then(res => {
        //     console.log(res)
        //     setSource(res)
        //     setLoading(false)
        //     setCompNo(input.tickers.length)

        // })

        setSource(input)
        setCompNo(input.tickers.length)
        setLoading(false)

        return( () => {})

    }, [input])

    return(
        <div>
            { loading ? 
                // <>
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    width: '100%',
                    height: '85vh'}}>
                    <CircularProgress size={'4rem'}/>
                </Box>
                // </> 
            : 
                <Box sx={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    width: '100%',
                    mt: '1rem',
                    }}>
                    <Paper
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ 
                            p: '2px 4px',
                            // display: 'flex', 
                            // justifyContent: 'center', 
                            // alignItems: 'center', 
                            // width: '60%',
                        }}>

                        <StyledInputBase
                            sx={{ ml: 1, flex: 1}}
                            placeholder="Search ticker"
                            inputProps={{ 
                                'aria-label': 'Search the industry',
                            }}
                            value={query} 
                            onChange={handleChange}
                            startAdornment={
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => {
                                        setQuery('')
                                        setResults([])
                                        }}
                                        style={{ visibility: results.length > 0 ? 'visible' : 'hidden' }}
                                        >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                </InputAdornment>
                            }/>
                    </Paper>
                </Box>
            }
            { results.length > 0  ? 

                <Stack 
                    direction={'column'} 
                    rowGap={2}
                    sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        // background: 'brown',
                        mt: '1rem',
                        }} >

                    <SearchCard input={results[0]} ticker={query} size={size}/>

                    <RadarScore radar={radar}/>

                </Stack>
                : 
                <div>
                    
                </div>
            }
        </div>
    )
}