import React , { useEffect, useState } from "react";
import Layout from "../components/layout";
import { Box, Container, Grid} from "@mui/material";
import { grey } from "@mui/material/colors";
import CircularProgress from '@mui/material/CircularProgress';
import { Helmet } from 'react-helmet';
import { ProductFirebase } from "../context/product-context";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { buttonColors } from '../theme/colors';
import CapStone from "../components/capStone";
import ProductCard from '../components/productCard';
import FreeProductCard from "../components/freeProductCard";
// import { alpha } from '@mui/material/styles';
import { alpha, useTheme } from '@mui/material/styles';
import SiteAlert from "../components/sitealert";


export default function Shop() {

    const { products } = ProductFirebase()
    const theme = useTheme()
  
    const [loading, setLoading] = useState(true)
    const [reports, setReports] = useState(null)

    const [subPeriod, setSubPeriod] = useState(
        {type: 'one_time', interval: ''}
        // {type: "recurring", interval: "month"}
    )

    const handleSubPeriod = (event, newPeriod) => {
        // console.log('New Sub: ', newPeriod)
        if (newPeriod !== null) {
            setSubPeriod(newPeriod);
        }
      };

    useEffect(() => {

        if (products == null) {
            // console.log('product (from Context) is null')
            if (reports == null) {
                // console.log('reports are null...')
                console.log('CASE 1: Lets wait for 1 sec...')
                setTimeout(() => {}, 1000)
            } else {
                // console.log("reports are available ...")
                console.log('CASE 2: Dont know how this is possible.')
                console.log(reports)
            }
        } else {
            // console.log('product (Context) is available ...')
            if (reports == null) {
                // console.log('reports are null...')
                console.log('CASE 3: Filling reports from available products.')
                setReports(products)
                // console.log(products)
                setLoading(false)
            } else {
                // console.log("reports are available ...")
                console.log('CASE 4: nothing is newly generated.')
                if (loading) {
                    // console.log('Loading was true, now chaned to false.')
                    setLoading(false)
                }
            }
        }

    }, [loading, products, reports] )

    return(
        <div>
            <Helmet>
                <title>Sector studies online shop</title>
                <meta name="description" content={'online shop for affordable sector reports for subscription and individual purchase'} />
                <meta name="keywords" content="sector report, online purchase, real estate, healthcare, health care, finance, financial, energy, utilities, consumer discretionary, industrials, information technology, technology, Communication Services, Consumer Staples, materials, basic materials" />

                <meta property="og:title" content={'Sector studies online shop'} />
                <meta property="og:description" content={'online shop for affordable sector reports for subscription and individual purchase'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={'https://farsight.report/shop'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'Sector studies online shop'} />
                <meta name="twitter:description" content={'online shop for affordable sector reports for subscription and individual purchase'} />
            </Helmet>  
            <Layout>
                { loading && 
                    <Container maxWidth='lg' >
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '85vh'}}>
                            <CircularProgress size={'4rem'}/>
                        </Box>
                    </Container>
      
                    } {!loading && 

                        <>
                        {/* <SiteAlert /> */}

                        <Container maxWidth='lg' sx={{
                            // background: theme.palette.background.default
                            }}>

                            <CapStone title='Sector Studies' />

                            <Box sx={{
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                // background: 'white'
                                }}>

                                <ToggleButtonGroup
                                    value={subPeriod}
                                    // style={{background: theme.palette.background.light}}
                                    exclusive
                                    sx={{background: 'white',}}
                                    // color="success"
                                    onChange={handleSubPeriod}
                                    aria-label="Subscription Intervals"
                                    >
                
                                    <ToggleButton 
                                        value={{type: "one_time", interval: ""}}
                                        sx={{
                                            width: {xs: '117px', md: '120px'},
                                            
                                            fontFamily: 'inherit', 
                                            '&:hover': {
                                                background: alpha(buttonColors[4], 0.6),
                                                color: grey[50]
                                            },
                                            '.Mui-selected': {

                                                    background: alpha(buttonColors[4], 0.6),
                                                    color: grey[50]

                                            },
                                            fontWeight: 400, 
                                            fontSize: {xs: 12, md: 14}}}>   
                                        One Time
                                    </ToggleButton>

                                    <ToggleButton 
                                        value={{type: "recurring", interval: "month"}}
                                        sx={{
                                            width: {xs: '116px', md: '120px'},
                                            fontFamily: 'inherit', 
                                            '&:hover': {
                                                background: alpha(buttonColors[5], 0.6),
                                                color: grey[50]
                                            },
                                            // '&&' : {
                                            //     background: alpha(buttonColors[4], 0.6),
                                            //     color: grey[50]
                                            // },
                                            fontWeight: 400, 
                                            fontSize: {xs: 12, md: 14}}}>
                                        Monthly
                                    </ToggleButton>

                                    <ToggleButton 
                                        value={{type: "recurring", interval: "year"}}  
                                        sx={{
                                            width: {xs: '117px', md: '120px'},
                                            fontFamily: 'inherit', 
                                            '&:hover': {
                                                background: alpha(buttonColors[6], 0.6),
                                                color: grey[50]
                                            },
                                            fontWeight: 400, 
                                            fontSize: {xs: 12, md: 14}}}>
                                        Yearly
                                    </ToggleButton>

                                </ToggleButtonGroup>
                            </Box>

                            <Grid 
                                container 
                                rowSpacing={2} 
                                columnSpacing={2}
                                sx={{
                                    display: 'flex', 
                                    alignItems: 'center',
                                    mt: '1%', 
                                    pb: '2%', 
                                    pt: '1%', 
                                    }}>

                                {
                                    reports.map(
                                        (report, index) => <ProductCard key={report.id} product={report} plan={subPeriod} index={index}/>
                                        // (report, index) => <FreeProductCard key={report.id} product={report} plan={subPeriod} index={index}/>
                                    )
                                }

                            </Grid>

                        </Container>

                        </>
                }
            </Layout>          
        </div>
    )
}