import React from "react";
import { Box } from "@mui/material";
import Chart from "react-apexcharts";
import { grey } from "@mui/material/colors";

export function RadarScore({radar}) {

    const options = {
        chart: {
            type: 'radar',
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            radar: {
                polygons: {
                    strokeColors: '#e9e9e9',
                    fill: {
                      colors: ['#f8f8f8', '#fff']
                    }
                }
            },
        },
        xaxis: {
            categories: [
                'Liquidity', 'Solvency', 'Profitability', 'Efficiency',
                'Market Value', 'Cashflow', 'Management'
            ],
            labels: {
                show: true,
                style: {
                    colors: [
                        '#212121', '#212121', '#212121', '#212121',
                        '#212121', '#212121', '#212121', '#212121'
                    ],
                    fontWeight: 500,
                },
            },
        },
        yaxis: {
            min: 0,
            max: 10
        }
    }

    return(
        <Box sx={{
            border:1,
            borderRadius: 2,
            background: 'white',
            width: {xs: '95%', md: '57%'}, 
            height: {xs: '380px', sm: '480px', md: '500px'}  
            }}>
            <Box sx={{m: 1, height: '95%'}}>
                <Chart series={radar} options={options} height={'100%'} type='radar' />
            </Box>
        </Box>
    )

}

export function ScoreHeat({scores}) {

    const options = {
        chart: {
            type: "heatmap",
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            heatmap: {
                shadeIntensity: 0.95,
                colorScale: {
                    inverse: true,
                },
            },
        },
        xaxis: {
            labels: {
                show: true,
            },
        },
    }

    return(
        <Box sx={{
            border:1,
            borderRadius: 2,
            background: 'white',
            width: {xs: '98%', sm: '85%', md: '75%'}, 
            height: {xs: '350px', sm: '400px', md: '450px', lg: '425px'}  
            }}>
            <Box sx={{m: 1, height: '95%'}}>
                <Chart series={scores} options={options} height={'100%'} type='heatmap' />
            </Box>
        </Box>
    )

}

export function McapHist({histo, name}) {

    const aa = {
        series: [
            {
                name: name,
                data: histo.nos,
            },
        ],
        options: {
            chart: {
                type: "bar",
                toolbar: {
                    show: false,
                },
              },
            plotOptions: {
                bar: {
                    columnWidth: '75%',
                    borderRadiusApplication: 'end',
                    borderRadius: 10,
                    distributed: true,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                      },
                }
            },
            dataLabels: {
                enabled: false,
                offsetY: -15,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            tooltip: {
                x: {
                    formatter: function(val, {w}){
                        return '<b>log(mcap)</b>= ' + val
                    }
                },
            },
            xaxis: {
                categories: histo.labels,
                axisBorder: {
                    show: false,
                },
                title: {
                    text: 'log10 (mcap, $M)',
                    style: {
                        fontWeight: 600,
                        fontSize: '12px',
                        fontFamily: 'Source Serif 4'
                    },
                },
            },
            legend: {
                show: false,
            },
            yaxis: {
                tickAmount: 8,
                title: {
                    text: 'No. of companies',
                    style: {
                        fontWeight: 600,
                        fontSize: '12px',
                        fontFamily: 'Source Serif 4'
                    },
                },
            },
            grid: {
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                }
            },
            colors: histo.colors,            
        }
    }

    return(
        <Box sx={{
            border:1,
            borderRadius: 2,
            background: 'white',
            width: {xs: '98%', sm: '85%', md: '75%'},
            height: {xs: '300px', sm: '350px', md: '400px', lg: '450px'}
            // minHeight: {xs: '400px'} 
            }}>
            <Box sx={{m: 1, height: '95%'}}>
                <Chart series={aa.series} options={aa.options} height={'100%'} type="bar"/>
            </Box>
        </Box>
    )
}

export function WeightedAverage({averages}) {

    const aa = {
        series: [
            {
                name: 'Simple Average',
                group: 'sim',
                data: [averages[0].s, averages[1].s, averages[2].s],
            }, {
                name: 'Weighted Average',
                group: 'we',
                data: [averages[0].w, averages[1].w, averages[2].w],
            }
        ],
        options: {
            chart: {
                type: "bar",
                // stacked: true,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    borderRadiusApplication: 'end',
                    borderRadius: 7,
                    // distributed: true,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                      },
                }
            },
            stroke: {
                show: true,
                width: 3,
                colors: ['transparent']
            },
            dataLabels: {
                enabled: false,
                offsetY: -20,
                style: {
                    fontSize: '10px',
                    colors: ["#304758"]
                }
            },
            xaxis: {
                categories: ['Year-to-date', '1-year', '3-year'],
                axisBorder: {
                    show: false,
                },
            },
            legend: {
                // show: false,
                position: 'top',
            },
            colors: ['#2ed1ff', '#d12eff'],
        },
    }

    return(
        <Box sx={{
            border:1,
            borderRadius: 2,
            background: 'white',
            width: {xs: '98%', sm: '85%', md: '75%'}, 
            height: {xs: '200px', sm: '250px', md: '300px', lg: '350px'}
            }}>
            <Box sx={{m: 1, height: '95%'}}>
                <Chart series={aa.series} options={aa.options} height={'100%'} type="bar"/>
            </Box>
        </Box>
    )

}

export function ReturnTop10(props) {

    const options = {
        chart: {
            type: "bar",
            toolbar: {
                show: false,
            },
          },
        plotOptions: {
            bar: {
                columnWidth: '45%',
                borderRadiusApplication: 'end',
                borderRadius: 7,
                distributed: true,
                dataLabels: {
                    position: 'top', // top, center, bottom
                  },
            }
        },
        dataLabels: {
            enabled: false,
            // offsetY: -20,
            // style: {
            //     fontSize: '12px',
            //     colors: ["#304758"]
            // }
        },
        tooltip: {
            y: {
                formatter: function(val, {w}){
                    return 'Return: ' + val
                }
            },
        },
        xaxis: {
            categories: props.data.names,
            axisBorder: {
                show: false,
            },
        },
        legend: {
            show: false,
        },
        // yaxis: {
        //     tickAmount: 5,
        //     max: 5,
        //     min: 0,
        // },
        grid: {
        padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
            }
        },
        colors: [
            '#e817ff',
            '#d12eff',
            '#ba45ff',
            '#a25dff',
            '#8b74ff',
            '#748bff',
            '#5da2ff',
            '#45baff',
            '#2ed1ff',
            '#17e8ff'
            ],
        };

    const aa = {
        series: [
            {
                name: props.name,
                data: props.data.data,
            },
        ],
    }

    return(
        <Box sx={{
            border:1,
            borderRadius: 2,
            background: 'white',
            width: {xs: '98%', sm: '85%', md: '75%'}, 
            height: {xs: '300px', sm: '350px', md: '400px', lg: '450px'}  
            }}>
            <Box sx={{m: 1, height: '95%'}}>
                <Chart series={aa.series} options={options} height={'100%'} type="bar"/>
            </Box>
        </Box>
    )
}

export function ScoreTop10({names, data, label}) {

    const options = {
        chart: {
            type: "bar",
            toolbar: {
                show: false,
            },
          },
        plotOptions: {
            bar: {
                columnWidth: '45%',
                borderRadiusApplication: 'end',
                borderRadius: 7,
                distributed: true,
                dataLabels: {
                    position: 'top', // top, center, bottom
                  },
            }
        },
        dataLabels: {
            enabled: false,
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        xaxis: {
            categories: names,
            axisBorder: {
                show: false,
            },
        },
        legend: {
            show: false,
        },
        yaxis: {
            tickAmount: 5,
            max: 10,
            min: 0,
        },
        grid: {
        padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
            }
        },
        colors: [
            '#e817ff',
            '#d12eff',
            '#ba45ff',
            '#a25dff',
            '#8b74ff',
            '#748bff',
            '#5da2ff',
            '#45baff',
            '#2ed1ff',
            '#17e8ff'
            ],
        };

    const aa = {
        series: [
            {
                name: label,
                data: data,
            },
        ],
    }

    return(
        <Box sx={{
            border:1,
            borderRadius: 2,
            background: 'white',
            width: {xs: '98%', sm: '85%', md: '75%'}, 
            height: {xs: '300px', sm: '350px', md: '400px', lg: '450px'} 
            }}>
            <Box sx={{m: 1, height: '95%'}}>
                <Chart series={aa.series} options={options} height={'100%'} type="bar"/>
            </Box>
        </Box>
    )
}

export function DivTop10({names, data, label}) {

    const options = {
        chart: {
            type: "bar",
            toolbar: {
                show: false,
            },
          },
        plotOptions: {
            bar: {
                columnWidth: '45%',
                borderRadiusApplication: 'end',
                borderRadius: 7,
                distributed: true,
                dataLabels: {
                    position: 'top', // top, center, bottom
                  },
            }
        },
        dataLabels: {
            enabled: false,
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        xaxis: {
            categories: names,
            axisBorder: {
                show: false,
            },
        },
        legend: {
            show: false,
        },
        yaxis: {
            logarithmic: true,
            logBase: 10,
            title: {
                text: label,
                offsetX: -2,
                style: {
                    color: grey[700],
                    fontSize: 12,
                    fontFamily: 'inherit',
                    fontWeight: 600,
                },
            },
            tickAmount: 5,
            // max: 100,
            min: 0.1,
            // stepsize: 1,
            forceNiceScale: true,
        },
        grid: {
        padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
            }
        },
        colors: [
            '#e817ff',
            '#d12eff',
            '#ba45ff',
            '#a25dff',
            '#8b74ff',
            '#748bff',
            '#5da2ff',
            '#45baff',
            '#2ed1ff',
            '#17e8ff'
            ],
        };

    const aa = {
        series: [
            {
                data: data,
            },
        ],
    }

    return(
        <Box sx={{
            border:1,
            borderRadius: 2,
            background: 'white',
            width: {xs: '98%', sm: '85%', md: '75%'}, 
            height: {xs: '300px', sm: '350px', md: '400px', lg: '450px'} 
            }}>
            <Box sx={{m: 1, height: '95%'}}>
                <Chart series={aa.series} options={options} height={'100%'} type="bar"/>
            </Box>
        </Box>
    )
}

export function MetricSector(props) {

    const int_options = {
        chart: {
            type: 'bubble',
            toolbar: {
                show: true,
                offsetX: -10,
                offsetY: 10,
                tools: {
                  download: false,
                  selection: true,
                  zoom: false,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset: true | '<img src="/static/icons/reset.png" width="20">',
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            opacity: 0.8
        },
        grid: {
            show: true,
            borderColor: grey[300],
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
                lines: {
                    show: true
                }
            },   
            yaxis: {
                lines: {
                    show: true
                }
            }, 
        },
        title: {
            text: props.name,
            margin: 20,
            style: {
                fontSize:  16,
                fontWeight:  400,
                fontFamily:  'inherit',
                color:  '#263238'
              },
        },
        tooltip: {
            x: {
                show: true,
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                    return props.mjsn['Series1'][dataPointIndex]['ticker'] + ',  r = ' + value + '%';
                }
            },
        },
        plotOptions: {
            bubble: {
                zScaling: true,
            },
        },
        xaxis: {
            type: 'numeric',
            min: -100,
            max: 150,
            tickAmount: 10,
            axisBorder: {
                show: false,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0
            },
        },
        yaxis: {
            tickAmount: 10,
            type: 'numeric',
            // min: 0,
            // max: 11,
            forceNiceScale: true,
            axisBorder: {
                show: false,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0
            },
        },
    }

    return(
        <Box sx={{
            border:1,
            borderRadius: 2,
            background: 'white',
            width: {xs: '98%', sm: '85%', md: '75%'}, 
            height: {xs: '300px', sm: '350px', md: '400px', lg: '450px'} 
            }}>
            <Box sx={{m: 1, height: '95%'}}>
                <Chart series={props.data.series} options={int_options} height={'100%'} type="bubble"/>
            </Box>
        </Box>
    )
}

export function MetricSector2({data, name, ticks}) {

    const int_options = {
        chart: {
            type: 'bubble',
            toolbar: {
                show: true,
                offsetX: -10,
                offsetY: 10,
                tools: {
                  download: false,
                  selection: true,
                  zoom: false,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset: true | '<img src="/static/icons/reset.png" width="20">',
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            opacity: 0.8
        },
        grid: {
            show: true,
            borderColor: grey[300],
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
                lines: {
                    show: true
                }
            },   
            yaxis: {
                lines: {
                    show: true
                }
            }, 
        },
        title: {
            text: name,
            margin: 20,
            style: {
                fontSize:  16,
                fontWeight:  400,
                fontFamily:  'inherit',
                color:  '#263238'
              },
        },
        tooltip: {
            x: {
                show: true,
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                    return ticks['Series1'][dataPointIndex]['ticker'] + ',  r = ' + value + '%';
                }
            },
            // y: {
            //     formatter: function(value) {
            //         return (10 ** value)
            //     }
            // },
        },
        plotOptions: {
            bubble: {
                zScaling: true,
            },
        },
        xaxis: {
            // type: 'numeric',
            // min: -100,
            max: 100,
            forceNiceScale: true,
            tickAmount: 10,
            axisBorder: {
                show: false,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0
            },
        },
        yaxis: {
            // logarithmic: true,
            // logBase: 100,
            tickAmount: 6,
            min: -1,
            // max: 100,
            forceNiceScale: true,
            axisBorder: {
                show: false,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0
            },
        },
    }

    return(
        <Box sx={{
            border:1,
            borderRadius: 2,
            background: 'white',
            width: {xs: '98%', sm: '85%', md: '75%'}, 
            height: {xs: '300px', sm: '350px', md: '400px', lg: '450px'} 
            }}>
            <Box sx={{m: 1, height: '95%'}}>
                <Chart series={data.series} options={int_options} height={'100%'} type="bubble"/>
            </Box>
        </Box>
    )
}

export function FinalSector({series, ticks, range, name}) {

    const int_options = {
        chart: {
            type: 'bubble',
            toolbar: {
                show: true,
                offsetX: -10,
                offsetY: 10,
                tools: {
                  download: false,
                  selection: true,
                  zoom: false,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset: true | '<img src="/static/icons/reset.png" width="20">',
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            opacity: 0.8
        },
        grid: {
            show: true,
            borderColor: grey[300],
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
                lines: {
                    show: false
                }
            },   
            yaxis: {
                lines: {
                    show: true
                }
            }, 
        },
        title: {
            text: name,
            margin: 20,
            style: {
                fontSize:  16,
                fontWeight:  400,
                fontFamily:  'inherit',
                color:  '#263238'
              },
        },
        tooltip: {
            x: {
                show: true,
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                    return ticks[seriesIndex]['tkdt'][dataPointIndex] + ',  <b>r = </b>' + value + '%';
                }
            },
            y: {
                title: {
                    formatter: (seriesName) => 'Final Score: ',
                },
            },
            z: {
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                    return Math.round( 100 * Math.log2(value) ) / 100
                  }
            },
        },
        plotOptions: {
            bubble: {
                zScaling: true,
            },
        },
        xaxis: {
            // type: 'numeric',
            // min: 7,
            // max: 70,
            tickAmount: 10,
            axisBorder: {
                show: false,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0
            },
        },
        yaxis: {
            tickAmount: 10,
            min: range[0] - 1,
            max: range[1] + 1,
            forceNiceScale: true,
            axisBorder: {
                show: true,
                color: grey[300],
                offsetX: 0,
                offsetY: 0
            },
        },
    }

    return(
        <Box sx={{
            border:1,
            borderRadius: 2,
            background: 'white',
            width: {xs: '98%', sm: '85%', md: '75%'}, 
            height: {xs: '450px', sm: '425px', md: '475px', lg: '500px'}  
            }}>
            <Box sx={{m: 1, height: '95%'}}>
                <Chart 
                series={series} 
                height={'100%'}
                // series={series} 
                options={int_options} type="bubble"/>
            </Box>
        </Box>
    )
}

export function ScoreSector({series, labels, name}) {

    const int_options = {
        chart: {
            type: 'bubble',
            toolbar: {
                show: true,
                offsetX: -10,
                offsetY: 10,
                tools: {
                  download: false,
                  selection: true,
                  zoom: false,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset: true | '<img src="/static/icons/reset.png" width="20">',
                },
            },
            zoom: {
                enabled: true,
                type: 'xy'
            },
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            opacity: 0.8
        },
        grid: {
            show: true,
            borderColor: grey[300],
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
                lines: {
                    show: false
                }
            },   
            yaxis: {
                lines: {
                    show: true
                }
            }, 
        },
        title: {
            text: name,
            margin: 20,
            style: {
                fontSize:  16,
                fontWeight:  400,
                fontFamily:  'inherit',
                color:  '#263238'
              },
        },
        tooltip: {
            x: {
                show: true,
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                    return labels['Series1'][dataPointIndex]['ticker'] + ',  r = ' + value + '%';
                }
            },
        },
        plotOptions: {
            bubble: {
                zScaling: true,
            },
        },
        xaxis: {
            type: 'numeric',
            min: -100,
            max: 150,
            tickAmount: 10,
            axisBorder: {
                show: false,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0
            },
        },
        yaxis: {
            tickAmount: 10,
            min: 0,
            max: 11,
            forceNiceScale: true,
            axisBorder: {
                show: true,
                color: grey[300],
                offsetX: 0,
                offsetY: 0
            },
        },
    }

    return(
        <Box sx={{
            border:1,
            borderRadius: 2,
            background: 'white',
            width: {xs: '98%', sm: '85%', md: '75%'}, 
            height: {xs: '300px', sm: '350px', md: '400px', lg: '450px'} 
            }}>
            <Box sx={{m: 1, height: '95%'}}>
                <Chart series={series.series} options={int_options} height={'100%'} type="bubble"/>
            </Box>
        </Box>
    )
}

export function FinalScoreHist({histo, name}) {

    const aa = {
        series: [
            {
                name: name,
                data: histo.nos,
            },
        ],
        options: {
            chart: {
                type: "bar",
                toolbar: {
                    show: false,
                },
              },
            plotOptions: {
                bar: {
                    columnWidth: '75%',
                    borderRadiusApplication: 'end',
                    borderRadius: 5,
                    distributed: true,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                      },
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            tooltip: {
                // x: {
                //     formatter: function(val, {w}){
                //         return '<b>log(mcap)</b>= ' + val
                //     }
                // },
                y: {
                    formatter: function(val, {w}){
                        return 'Companies: ' + val
                    }                    
                }
            },
            xaxis: {
                categories: histo.bins,
                axisBorder: {
                    show: false,
                },
                title: {
                    text: 'Final scores',
                    style: {
                        fontWeight: 600,
                        fontSize: '12px',
                        fontFamily: 'Source Serif 4'
                    },
                },
            },
            legend: {
                show: false,
            },
            yaxis: {
                tickAmount: 8,
                title: {
                    text: 'No. of companies',
                    style: {
                        fontWeight: 600,
                        fontSize: '12px',
                        fontFamily: 'Source Serif 4'
                    },
                },
            },
            grid: {
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                }
            },
            colors: [
                '#11eeff', '#22ddff', '#33ccff', '#44bbff', '#55aaff', '#6699ff', '#7788ff',
                '#8877ff', '#9966ff', '#aa55ff', '#bb44ff', '#cc33ff', '#dd22ff', '#ee11ff',
            ],            
        }
    }

    return(
        <Box sx={{
            border:1,
            borderRadius: 2,
            background: 'white',
            width: {xs: '98%', sm: '85%', md: '75%'}, 
            height: {xs: '300px', sm: '350px', md: '400px', lg: '450px'} 
            }}>
            <Box sx={{m: 1, height: '95%'}}>
                <Chart series={aa.series} options={aa.options} height={'100%'} type="bar"/>
            </Box>
        </Box>
    )
}

export function RatioPie() {

    const aa = {
        series: [1, 1, 1, 1, 1, 1, 1],
        options: {
            chart: {
                type: 'donut',
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opt) {
                    switch (opt.seriesIndex) {
                        case 0:
                            return ["Liquidity", "Ratios"]
                        case 1:
                            return ["Solvency", "Ratios"]
                        case 2:
                            return ["Profitability", "Ratios"]
                        case 3:
                            return ["Efficiency", "Ratios"]
                        case 4:
                            return ["Mkt. Val.", "Ratios"]
                        case 5:
                            return ["Cashflow", "Ratios"]
                        case 6:
                            return ["Mangm.", "Ratios"]
                    default:
                        return val;
                    }
                },
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    // color: '#000',
                    opacity: 0.45
                }
              },
            plotOptions: {
                pie: {
                    startAngle: 0,
                    expandOnClick: false,
                    background: 'transparent',
                    donut: {
                        size: '55%',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                            },
                            total: {
                                label: 'Ratio Analysis',
                                showAlways: true,
                                show: true,
                                fontSize: {xs: 12, md: 18},
                                fontFamily: 'inherit',
                                fontWeight: 600,
                                // color: '#616161',
                            },
                            value: {
                                show: false,
                            },
                        },
                    },
                },
            },
            legend: {
                show: false,
            },
            tooltip: {
                enabled: false,
                x: {
                    show: false,
                },
            },
            // colors: ['#4682b4', '#00ff7f', '#d2691e', '#dc143c', '#483d8b', '#dda0dd', '#808000'],
            // New to match the theme
            colors: ['#11eeff', '#aa55ff', '#33ccff', '#cc33ff', '#55aaff', '#ee11ff', '#7788ff'],
        },

    }

    return (
        <Chart series={aa.series} options={aa.options} height={'100%'} type="donut"/>
    )
}

export function IntroHeat() {
    const aa = {
        series: [
            {
                name: "Liquidity",
                data: [{
                    x: 'XXA',
                    y: 4.8
                }, {
                    x: 'WJV',
                    y: 6.9
                }, {
                    x: 'XYZ',
                    y: 7.4
                }, {
                    x: 'YWX',
                    y: 5.0
                }, {
                    x: 'VWX',
                    y: 8.3                    
                }, {
                    x: 'QYY',
                    y: 6.8 
                }, {
                    x: 'ZJQ',
                    y: 5.4
                }
                ]
            }, 
            {
                name: "Solvency",
                data: [{
                    x: 'XXA',
                    y: 6.7
                }, {
                    x: 'WJV',
                    y: 5.4
                }, {
                    x: 'XYZ',
                    y: 4.7
                }, {
                    x: 'YWX',
                    y: 8.6
                }, {
                    x: 'VWX',
                    y: 6.2                    
                }, {
                    x: 'QYY',
                    y: 4.0 
                }, {
                    x: 'ZJQ',
                    y: 9.2
                }
                ]
            }, 
            {
                name: "Profitability",
                data: [{
                    x: 'XXA',
                    y: 8.9
                }, {
                    x: 'WJV',
                    y: 6.8
                }, {
                    x: 'XYZ',
                    y: 2.9
                }, {
                    x: 'YWX',
                    y: 5.1
                }, {
                    x: 'VWX',
                    y: 7.8                    
                }, {
                    x: 'QYY',
                    y: 6.7 
                }, {
                    x: 'ZJQ',
                    y: 4.7
                }
                ]
            }, 
            {
                name: "Efficiency",
                data: [{
                    x: 'XXA',
                    y: 6.3
                }, {
                    x: 'WJV',
                    y: 7.0
                }, {
                    x: 'XYZ',
                    y: 4.6
                }, {
                    x: 'YWX',
                    y: 8.4
                }, {
                    x: 'VWX',
                    y: 5.1                    
                }, {
                    x: 'QYY',
                    y: 7.5 
                }, {
                    x: 'ZJQ',
                    y: 5.9
                }
                ]
            }, 
            {
                name: "Mkt. Val.",
                data: [{
                    x: 'XXA',
                    y: 3.2
                }, {
                    x: 'WJV',
                    y: 9.0
                }, {
                    x: 'XYZ',
                    y: 4.8
                }, {
                    x: 'YWX',
                    y: 6.2
                }, {
                    x: 'VWX',
                    y: 7.6                    
                }, {
                    x: 'QYY',
                    y: 6.6 
                }, {
                    x: 'ZJQ',
                    y: 8.1
                }
                ]
            },
            {
                name: "Cashflow",
                data: [{
                    x: 'XXA',
                    y: 5.9
                }, {
                    x: 'WJV',
                    y: 6.4
                }, {
                    x: 'XYZ',
                    y: 8.1
                }, {
                    x: 'YWX',
                    y: 7.9
                }, {
                    x: 'VWX',
                    y: 5.8                    
                }, {
                    x: 'QYY',
                    y: 4.9 
                }, {
                    x: 'ZJQ',
                    y: 6.3
                }
                ]
            },
            {
                name: "Mgmt.",
                data: [{
                    x: 'XXA',
                    y: 8.4
                }, {
                    x: 'WJV',
                    y: 6.8
                }, {
                    x: 'XYZ',
                    y: 5.9
                }, {
                    x: 'YWX',
                    y: 7.6
                }, {
                    x: 'VWX',
                    y: 9.0                    
                }, {
                    x: 'QYY',
                    y: 6.7 
                }, {
                    x: 'ZJQ',
                    y: 8.6
                }
                ]
            }
        ],
        options: {
            chart: {
                type: "heatmap",
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                heatmap: {
                    shadeIntensity: 0.95,
                    colorScale: {
                        inverse: false,
                    },
                },
            },
            xaxis: {
                labels: {
                    show: true,
                },
            },
            yaxis: {
                labels: {
                    show: true,
                    rotate: -90
                },
            },
        }
    }
    return (
        <Chart series={aa.series} options={aa.options} height={'100%'} type="heatmap"/>
    )
}

export function IntroRadar() {
    const aa = {
        series: [
            {
                name: "Regulated Water",
                data: [4.98, 5.21, 5.61, 4.11, 5.34, 6.67]
              },
              {
                name: "Renewable Utilities",
                data: [4.5, 3.85, 4.19, 4.6, 4.02, 3]
              }
        ],
        options: {
            chart: {
                type: 'radar',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                radar: {
                    polygons: {
                        strokeColors: '#e9e9e9',
                        fill: {
                          colors: ['#f8f8f8', '#fff']
                        }
                    }
                },
            },
            xaxis: {
                categories: [
                    'Liquidity', 'Solvency', 'Profitability', 'Efficiency',
                    'Market Value', 'Cashflow', 'Management'
                ],
                labels: {
                    show: true,
                    style: {
                        colors: [
                            '#212121', '#212121', '#212121', '#212121',
                            '#212121', '#212121', '#212121', '#212121'
                        ],
                        fontWeight: 500,
                    },
                },
            },
            yaxis: {
                min: 0,
                max: 10
            }
        },
    }
    return(
        <Chart series={aa.series} options={aa.options} height={'100%'} type='radar' />
    )
}