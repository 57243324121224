import React from "react";
import { Typography, Box, IconButton, Container } from "@mui/material";
import { Link } from 'react-router-dom'
import InstagramIcon from '@mui/icons-material/Instagram';
// import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import { grey } from "@mui/material/colors";
import { useTheme } from '@mui/material/styles';


const Footer = () => {

    const year = new Date().getFullYear()
    const theme = useTheme()

    return(

        <Container 
            maxWidth='false' 
            sx={{
                borderTop: 2, 
                borderColor: grey[900],
                // background: theme.palette.background.dark,
                }}>

            <Box 
                sx={{   
                display: 'flex',
                alignItems: 'center',
                height: '180px',
                width: '100%',
                }}>

                <Box sx={{
                    width: '40%',
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    justifyContent: 'space-evenly'}}>

                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <Typography textAlign={'left'} sx={{fontSize: 20, fontWeight: 500, ml: '5%'}}>
                            FARSIGHT
                        </Typography>
                    </Box>

                    <Box sx={{width: '100%', display: {xs: 'flex', md:'none'}, alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <Typography sx={{fontSize: 14, fontWeight: 400}}>
                            &copy; {year}
                        </Typography>
                    </Box>

                    <Box sx={{width: '100%', display: {xs: 'none', md:'flex'}, alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <Typography sx={{fontSize: 14, fontWeight: 400}}>
                            &copy; {year} All Rights Reserved.
                        </Typography>
                    </Box>

                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <Link to={'/privacy'} style={{textDecoration: 'none', color: grey[900]}}>
                            <Typography sx={{fontSize: 14, fontWeight: 400}}>
                                Privacy
                            </Typography>
                        </Link>
                    </Box>

                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <Link to={'/terms'} style={{textDecoration: 'none', color: grey[900]}}>
                            <Typography sx={{fontSize: 14, fontWeight: 400}}>
                                Terms
                            </Typography>
                        </Link>
                    </Box>

                </Box>
                
                <Box sx={{
                    width: '30%',
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    justifyContent: 'space-evenly'}}>

                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <Typography sx={{fontSize: 20, fontWeight: 500}}>
                            COMPANY
                        </Typography>
                    </Box>

                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <Link to={'/about'} style={{textDecoration: 'none', color: grey[900]}}>
                            <Typography sx={{fontSize: 16, fontWeight: 400}}>
                                About
                            </Typography>
                        </Link>
                    </Box>

                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <Link to={'/contact'} style={{textDecoration: 'none', color: grey[900]}}>
                            <Typography sx={{fontSize: 16, fontWeight: 400}}>
                                Contact
                            </Typography>
                        </Link>
                    </Box>

                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <IconButton 
                            disableRipple={false} 
                            component={Link} 
                            to={'http://www.twitter.com/'}>
                            <TwitterIcon />  
                        </IconButton>
                    </Box>

                </Box>

                <Box sx={{
                    width: '30%', 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    justifyContent: 'center'}}>

                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <Typography sx={{fontSize: 20, fontWeight: 500}}>
                            PRODUCTS
                        </Typography>
                    </Box>

                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <Typography sx={{fontSize: 16, fontWeight: 400}}>
                            Blog
                        </Typography>
                    </Box>

                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <Link to={'/solutions'} style={{textDecoration: 'none', color: grey[900]}}>
                            <Typography sx={{fontSize: 16, fontWeight: 400}}>
                                Solutions
                            </Typography>
                        </Link>
                    </Box>

                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <IconButton disableRipple={false} component={Link} to={'http://www.instagram.com/farsight_reports'}>
                            <InstagramIcon />
                        </IconButton>
                    </Box>

                </Box>

            </Box>

        </Container>
    )
}

export default Footer